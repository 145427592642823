.TextF {
  height: 50px;
  width: 100%;
  font-size: 2em;
}
.TextF label {
  font-size: 2em;
}

.gameRegisterMain {
  background: url("/img/fondo.png") repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  position: absolute;
}
