.TextF {
  height: 50px;
  width: 100%;
  font-size: 2em;
}
.TextF label {
  font-size: 2em;
}

.gameRegisterMainSlot {
  background: url("/img/back-jackpot.jpg") repeat center center local;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  width: 100%;
  min-height: 100%;
  position: absolute;
  padding-top: 0px;
  margin-top: -40px;
  font-family: Montserrat;
}
