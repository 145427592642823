.App {
  text-align: center;
}

body {
  touch-action: pan-x pan-y;
}

.gameMain1 {
  background: url("/img/fondo.png") repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 110%;
  width: 100%;
  position: fixed;
}

.gameMain {
  background: url("/img/fondo.png") repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 110%;
  width: 100%;
  position: absolute;
}
