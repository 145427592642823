.App {
  text-align: center;
}

body {
  touch-action: pan-x pan-y;
}

.gameMain {
  background: url("/img/fondo.png") repeat top center local;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  padding-top: 300px;
}
